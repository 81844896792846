<template>
  <div class="wx">
    <img @click="getScheme" src="../assets/img/wx.png" width="100%" alt="">
  </div>
</template>

<script>
import {
  scheme
} from '@/api/wechat';
export default {
  data() {
    return {
      
    }
  },
  mounted(){
  },
  methods: {
    getScheme () {
      scheme().then(res =>{
        location.href = res.data.data
      })
    }
  }
}
</script>
<style scoped lang="scss">
  
</style>
